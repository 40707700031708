import { Button, Image, Space } from 'antd'

export const GoogleSignInButton = ({ text, handleSignIn }) => {
  return (
    <Button
      className="bg-gray-100 text-gray-700 border-none text-sm"
      block
      size="large"
      onClick={handleSignIn}
    >
      <Space direction="horizontal" align="center">
        <Image
          preview={false}
          style={{ width: 32 }}
          className="inline-block"
          src="/thirdParties/google_g.png"
        />
        {text}
      </Space>
    </Button>
  )
}
