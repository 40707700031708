import { useTranslation } from 'next-i18next'

const CaseStudySection = () => {
  const { t } = useTranslation('landingpage-admin/case-study')

  return (
    <section
      id="case-study"
      className="w-full flex flex-col items-center py-12 px-4 md:py-24 md:px-6 gap-y-12 md:gap-y-16 bg-[#F9FAFB] lg:px-[100px]"
    >
      <div className="flex flex-col items-center gap-y-2">
        <h2 className="mb-0 font-semibold font-inter text-gr900 text-center text-2xl md:text-3xl lg:text-4xl">
          {t('caseStudyHeading')}
        </h2>
        <p className="font-inter text-gr600 text-center mt-2 text-base md:text-lg">
          {t('caseStudySubheading')}
        </p>
      </div>

      <div className="w-full flex flex-col items-center gap-y-8">
        <div className="flex flex-col items-center text-center max-w-4xl px-4">
          <h3 className="font-inter mb-0 font-semibold text-xl md:text-2xl text-gr900 mt-5">
            {t('caseStudySaveTimeCutCosts')}
          </h3>
          <div className="overflow-x-auto mt-5 w-full">
            <table className="w-full border-collapse text-xs md:text-sm lg:text-base">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border-b-2 border-gray-300 p-2 md:p-4">
                    {t('caseStudyScenario')}
                  </th>
                  <th className="border-b-2 border-gray-300 p-2 md:p-4">
                    {t('caseStudyWithoutTraqqie')}
                  </th>
                  <th className="border-b-2 border-gray-300 p-2 md:p-4">
                    {t('caseStudyWithTraqqie')}
                  </th>
                  <th className="border-b-2 border-gray-300 p-2 md:p-4">
                    {t('caseStudySavings')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b border-gray-200 p-2 md:p-4">
                    {t('caseStudyTimeSpentWBSOAdmin')}
                  </td>
                  <td className="border-b border-gray-200 p-2 md:p-4">
                    {t('caseStudyTimeWithoutTraqqie')}
                  </td>
                  <td className="border-b border-gray-200 p-2 md:p-4">
                    {t('caseStudyTimeWithTraqqie')}
                  </td>
                  <td className="border-b border-gray-200 p-2 md:p-4">
                    {t('caseStudyTimeSavings')}
                  </td>
                </tr>
                <tr className="bg-gray-50">
                  <td className="border-b border-gray-200 p-2 md:p-4">
                    {t('caseStudyCostOfTime')}
                  </td>
                  <td className="border-b border-gray-200 p-2 md:p-4">
                    {t('caseStudyCostWithoutTraqqie')}
                  </td>
                  <td className="border-b border-gray-200 p-2 md:p-4">
                    {t('caseStudyCostWithTraqqie')}
                  </td>
                  <td className="border-b border-gray-200 p-2 md:p-4">
                    {t('caseStudyCostSavings')}
                  </td>
                </tr>
                <tr>
                  <td className="border-b border-gray-200 p-2 md:p-4">
                    {t('caseStudyTraqqieSubscription')}
                  </td>
                  <td className="border-b border-gray-200 p-2 md:p-4">
                    {t('caseStudySubscriptionWithoutTraqqie')}
                  </td>
                  <td className="border-b border-gray-200 p-2 md:p-4">
                    {t('caseStudySubscriptionWithTraqqie')}
                  </td>
                  <td className="border-b border-gray-200 p-2 md:p-4">-</td>
                </tr>
                <tr>
                  <td className="border-b border-gray-200 p-2 md:p-4 font-semibold">
                    {t('caseStudyNetSavingsYearly')}
                  </td>
                  <td className="border-b border-gray-200 p-2 md:p-4">-</td>
                  <td className="border-b border-gray-200 p-2 md:p-4">-</td>
                  <td className="border-b border-gray-200 p-2 md:p-4">
                    <strong>{t('caseStudyNetSavingsAmount')}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="font-inter text-gr600 mt-2 text-xs md:text-sm">
            {t('caseStudySalaryAssumption')}
          </p>
        </div>

        <div className="flex flex-col items-center text-left max-w-4xl px-4">
          <h2 className="font-inter mb-0 font-semibold text-xl md:text-2xl text-gr900 mt-5">
            {t('caseStudyEnjoyPeaceOfMind')}
          </h2>
          <ul className="list-none mt-5 space-y-2 md:space-y-4">
            <li className="font-inter text-gr600 text-sm md:text-base">
              <strong>{t('caseStudyHappyEngineers')}</strong>{' '}
              {t('caseStudyHappyEngineersDescription')}
            </li>
            <li className="font-inter text-gr600 text-sm md:text-base">
              <strong>{t('caseStudyComplianceSimplified')}</strong>{' '}
              {t('caseStudyComplianceSimplifiedDescription')}
            </li>
            <li className="font-inter text-gr600 text-sm md:text-base">
              <strong>{t('caseStudyStreamlinedProcesses')}</strong>{' '}
              {t('caseStudyStreamlinedProcessesDescription')}
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default CaseStudySection
