import { Alert, Typography } from 'antd'
import cn from 'classname'
import { useAuth } from 'components/auth'
import { useTranslation } from 'next-i18next'
import { trackEvent } from 'utils/analytics'

const AlertNotAvailable = () => {
  const { t } = useTranslation('landingpage-admin/partner')
  const { authUser } = useAuth()

  const handleClick = () => {
    window.history.pushState(
      {},
      'integration_request',
      '?integration_request=true',
    )

    if (window.HubSpotConversations && window.HubSpotConversations.widget) {
      setTimeout(() => {
        window.HubSpotConversations.widget.open()
      }, 200) // Short delay to ensure close completes before refresh
      trackEvent(
        'User clicked reach out for Integration',
        { chat: true },
        authUser?.uuid,
      )
    } else {
      // If HubSpot chat widget is not available, fall back to mailto link
      const mailtoLink = `mailto:request@traqqie.com?subject=Please%20add%20%5BYour%20Integration%5D&body=Dear%20Traqqie%20Team,%0D%0A%0D%0AI%20would%20like%20to%20use%20Traqqie.%20Could%20you%20please%20add%20%5BYour%20Integration%5D%20to%20Traqqie?%0D%0A%0D%0AThank%20you.%0D%0AYOUR%20NAME`
      const emailLink = document.createElement('a')
      emailLink.href = mailtoLink
      emailLink.target = '_blank'
      emailLink.click()

      trackEvent(
        'User clicked reach out for Integration',
        { chat: false },
        authUser?.uuid,
      )
    }
  }

  return (
    <Alert
      message={
        <span>
          {t('alertMessage')}{' '}
          <Typography.Link onClick={handleClick}>
            {t('reachOutLink')}
          </Typography.Link>
        </span>
      }
      type="success"
      style={{
        backgroundColor: '#f6ffed',
        borderColor: '#b7eb8f',
        borderRadius: 8,
        padding: '12px 24px',
      }}
      showIcon={false}
    />
  )
}

const PartnerSection = ({ title, partners, showAlert = false }) => {
  return (
    <section
      id="partner"
      className="flex flex-col items-center py-12 px-6 gap-y-12 md:py-24 lg:px-[100px]"
    >
      <h2 className="font-inter font-medium text-base text-gr600 text-center">
        {title}
      </h2>
      <ul
        className={cn(
          'w-full flex flex-col items-center gap-y-10 flex-wrap',
          'sm:gap-x-8 sm:flex-row sm:justify-center sm:items-start',
          'xl:gap-x-16',
        )}
      >
        {partners.map(({ text, path, width, height }) => {
          return (
            <img
              key={text}
              width={width}
              height={height}
              alt={text}
              src={path}
              className="h-8 w-auto object-cover block lg:h-12"
            />
          )
        })}
      </ul>
      {showAlert && <AlertNotAvailable />}
    </section>
  )
}

export default PartnerSection
