import { Card, Col, Divider, Row, Typography } from 'antd'
import { GoogleSignInButton } from 'components/GoogleSignInButton/GoogleSignInButton'
import CollectEmailForDemo from './CollectEmailForDemo'

import { useTranslation } from 'next-i18next'

const SignInOrDemo = ({ handleSignIn, handleDemoClick }) => {
  const { t } = useTranslation('signup')

  return (
    <section id="demo">
      <Row
        align="middle"
        justify="center"
        className="h-screen p-4"
        gutter={24}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {/* Demo Availability Announcement */}
        <Col
          xs={24}
          sm={10}
          lg={8}
          xl={6}
          style={{ display: 'flex', justifyContent: 'center' }}
          className="text-center"
        >
          <Card
            style={{
              width: '100%',
              maxWidth: '400px',
              height: 'auto',
              minHeight: '340px',
              alignItems: 'center',
            }}
          >
            <Typography.Title level={3}>
              {t('demo_or_signup_title')}
            </Typography.Title>
            <Typography.Paragraph>
              {t('demo_or_signup_description')}
            </Typography.Paragraph>
            <CollectEmailForDemo handleDemoClick={handleDemoClick} />
          </Card>
        </Col>

        {/* Custom Dividers with 'OR' */}
        <Col
          xs={0}
          sm={2}
          lg={1}
          xl={1}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="text-center"
        >
          <Divider
            type="vertical"
            style={{ height: '40%', minHeight: '150px' }}
          />
          <span
            style={{
              fontWeight: 'bold',
              color: '#333',
              margin: '4px',
              fontSize: '18px',
              padding: '0 8px',
            }}
          >
            {t('or')}
          </span>
          <Divider
            type="vertical"
            style={{ height: '40%', minHeight: '150px' }}
          />
        </Col>

        {/* Sign In Section */}
        <Col
          xs={24}
          sm={10}
          lg={8}
          xl={6}
          style={{ display: 'flex', justifyContent: 'center' }}
          className="text-center"
        >
          <Card
            style={{
              width: '100%',
              maxWidth: '400px',
              height: 'auto',
              minHeight: '340px',
            }}
          >
            <Typography.Title
              level={3}
              // className="text-xl font-semibold text-center"
            >
              {t('demo_or_signup_traqqie_users')}
            </Typography.Title>
            <Row
              align="middle"
              justify="center"
              className="px-4"
              gutter={[16, 24]}
            >
              {t('demo_or_signup_member_explanation')}
              <Col span={24} className="text-center">
                <GoogleSignInButton
                  handleSignIn={handleSignIn}
                  text={t('login_with_google').toUpperCase()}
                />
              </Col>
              {t('or')}
              <Col span={24} className="text-center">
                <GoogleSignInButton
                  handleSignIn={handleSignIn}
                  text={t('signup_with_google').toUpperCase()}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </section>
  )
}

export default SignInOrDemo
