import LandingPageComponent from 'components/LandingPage/LandingPage'
import { generateAlternatesAndCanonical } from 'data/seo/seo'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'

const LandingPage = ({ locale }) => {
  const { t } = useTranslation('landingpage-admin/meta')

  return (
    <>
      <NextSeo
        title={t('meta_title')}
        description={t('meta_description')}
        languageAlternates={generateAlternatesAndCanonical(locale).alternates}
        canonical={generateAlternatesAndCanonical(locale).canonoical}
        openGraph={{
          title: t('meta_title'),
          description: t('meta_description'),
        }}
        // canonical=""
      />
      <LandingPageComponent locale={locale} />
    </>
  )
}

export default LandingPage
