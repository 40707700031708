import NavigationalButton from 'components/ButtonV2/NavigationalButton'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { PAGE } from 'utils/pages'

const TrialSection = () => {
  const router = useRouter()
  const { t } = useTranslation('landingpage-admin/trail')

  return (
    <section
      id="#trial"
      className="w-full flex flex-col items-center py-24 px-6 gap-y-16 bg-[#F9FAFB] lg:px-[100px]"
    >
      <div className="w-full flex flex-col items-center max-w-[1216px] md:flex-row md:items-start md:justify-between">
        <div className="flex flex-col items-center text-center gap-y-4 md:items-start">
          <span className="font-semibold text-4xl text-gr900">
            {t('trial_title')}
          </span>
          <span className="text-gray-600">{t('trial_subtitle')}</span>
        </div>

        <NavigationalButton
          href={PAGE.signin}
          className="mt-12 md:mt-0"
          onClick={() => router.push(PAGE.signin)}
        >
          {t('trial_cta')}
        </NavigationalButton>
      </div>
    </section>
  )
}

export default TrialSection
