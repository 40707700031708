import LandingPage from 'views/LandingPage/LandingPage'

import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

const LandingPageLocalized = ({ locale }) => {
  return <LandingPage locale={locale} />
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      locale,
      ...(await serverSideTranslations(locale, [
        'landingpage-admin/case-study',
        'landingpage-admin/works',
        'landingpage-admin/hero',
        'landingpage-admin/trail',
        'landingpage-admin/pricing',
        'landingpage-admin/partner',
        'landingpage-admin/meta',
        'nav',
        'offer',
        'signup',
      ])),
    },
  }
}

export default LandingPageLocalized
