export const PARTNERS_ADMIN = [
  {
    text: 'Trello',
    path: '/images/partners/trello.png',
    width: 142,
    height: 48,
  },
  { text: 'Asana', path: '/images/partners/asana.png', width: 74, height: 48 },
  {
    text: 'Linear',
    path: '/images/partners/linear.png',
    width: 192,
    height: 48,
  },
  {
    text: 'Atlassian',
    path: '/images/partners/atlasian.png',
    width: 309,
    height: 48,
  },
  {
    text: 'Shortcut',
    path: '/images/partners/shortcut.png',
    width: 175,
    height: 48,
  },
]

export const PARTNERS_APPLICATIONS = [
  {
    text: 'Firebase',
    path: '/images/partners/firebase.png',
    width: 142,
    height: 48,
  },
  { text: 'AWS', path: '/images/partners/aws.svg', width: 74, height: 48 },

  {
    text: 'OpenAI',
    path: '/images/partners/openai.png',
    width: 309,
    height: 48,
  },
]

export const WORK_TEXTS = [
  {
    title: 'Effortless Time-Logging',
    icon: '/icons/simple.svg',
    desc: 'Rapid Recording: Time-tracking made easy. Just drag, drop, and consider it done.',
  },
  {
    title: 'Seamless Integration',
    icon: '/icons/integrated.svg',
    desc: 'One Stop Shop: Traqqie melds with your project mangement system & calendar,you have all important information in one place.',
  },
  {
    title: 'Always Compliant',
    icon: '/icons/keep-track.svg',
    desc: 'Stay in the Safe Zone: With auto-checks on all time entries, compliance is a given. Extra information makes back-tracing easy.',
  },
  {
    title: 'Timely Reminders',
    icon: '/icons/reminders.svg',
    desc: 'On Your Schedule: Receive reminders and stay consistently updated with your WBSO logs',
  },
]

export const PRICING_PERIODS = [
  { text: 'Monthly billing', value: 'monthly' },
  { text: 'Annual billing', value: 'annual' },
]
export const PRICING_OPT = {
  monthly: [
    {
      price: '€10',
      title: 'Starter plan',
      subtitle: 'Ideal for Small Teams & Start Ups',
      isPopular: true,
      benefits: [
        {
          text: 'per hour registered 0.50 € ( only ~ 2.5% of WBSO potential)',
          isGiven: true,
        },
        // { text: 'Reminder emails', isGiven: true },
        { text: 'Unlimited Employees (max. 15)', isGiven: false },
      ],
    },
    {
      price: '€17',
      title: 'Expansion plan',
      subtitle: 'Ideal for Scale Ups & Corporates',
      isPopular: false,
      benefits: [
        {
          text: 'per hour registered 0.30  € ( only ~ 1.5% of WBSO potential)',
          isGiven: true,
        },
        // { text: 'Reminder emails', isGiven: true },
        { text: 'Unlimited Employees', isGiven: true },
      ],
    },
  ],
  annual: [
    {
      price: '€100',
      title: 'Starter plan',
      subtitle: 'Ideal for Small Teams & Start Ups',
      isPopular: true,
      benefits: [
        {
          text: 'per hour registered 0.50 € ( only ~ 2.5% of WBSO potential)',
          isGiven: true,
        },
        // { text: 'Reminder emails', isGiven: true },
        { text: 'Unlimited Employees (max. 15)', isGiven: false },
      ],
    },
    {
      price: '€170',
      title: 'Expansion plan',
      subtitle: 'Ideal for Scale Ups & Corporates',
      isPopular: false,
      benefits: [
        {
          text: 'per hour registered 0.30  € ( only ~ 1.5% of WBSO potential)',
          isGiven: true,
        },
        // { text: 'Reminder emails', isGiven: true },
        { text: 'Unlimited Employees', isGiven: true },
      ],
    },
  ],
}
